import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears
} from 'date-fns'

export const handleTimeDifference = (now, createdAt) => {
  const diffInSeconds = differenceInSeconds(new Date(now), new Date(createdAt))
  const diffInMinutes = differenceInMinutes(new Date(now), new Date(createdAt))
  const diffInHours = differenceInHours(new Date(now), new Date(createdAt))
  const diffInDays = differenceInDays(new Date(now), new Date(createdAt))
  const diffInMonths = differenceInMonths(new Date(now), new Date(createdAt))
  const diffInYears = differenceInYears(new Date(now), new Date(createdAt))

  if (diffInSeconds < 60) {
    return `${diffInSeconds} segundos atrás`
  } else if (diffInMinutes < 60) {
    if (diffInMinutes > 1) {
      return `${diffInMinutes} minutos atrás`
    } else {
      return `${diffInMinutes} minuto atrás`
    }
  } else if (diffInHours < 24) {
    if (diffInHours > 1) {
      return `${diffInHours} horas atrás`
    } else {
      return `${diffInHours} hora atrás`
    }
  } else if (diffInDays < 30) {
    if (diffInDays > 1) {
      return `${diffInDays} dias atrás`
    } else {
      return `${diffInDays} dia atrás`
    }
  } else if (diffInMonths < 12) {
    if (diffInMonths > 1) {
      return `${diffInMonths} meses atrás`
    } else {
      return `${diffInMonths} mês atrás`
    }
  } else {
    if (diffInYears === 1) {
      return `${diffInYears} anos atrás`
    } else {
      return `${diffInYears} ano atrás`
    }
  }
}
